@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import '~react-redux-toastr/lib/css/react-redux-toastr.min.css';
@import '~@uppy/core/dist/style.css';
@import '~@uppy/dashboard/dist/style.css';
@import '~@uppy/drag-drop/dist/style.css';
@import '~react-image-lightbox/style.css';
@import '~animate.css/animate.min.css';
@import '../Css/table.scss';
@import '../Css/component.scss';

@font-face {
  font-family: Poppins;
  src: url('../Fonts/Poppins-Regular.otf');
}
@font-face {
  font-family: PoppinsSemibold;
  src: url('../Fonts/Poppins-SemiBold.otf');
}
@font-face {
  font-family: PoppinsBold;
  src: url('../Fonts/Poppins-Bold.otf');
}

html {
  overflow: hidden;
}
body {
  overflow: hidden;
  margin: 0;
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.headerTemplate {
  height: 48px !important;
  line-height: 46px;
  padding-left: 0px;
  padding-right: 0px;
  display: flex;
  position: relative;
  justify-content: flex-start;
  flex-flow: row nowrap;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.colHeaderLeft,
.colHeaderRight {
  line-height: 46px;
}
.templateMenu li.ant-menu-item,
.templateMenu li.ant-menu-submenu div {
  padding-left: 21px !important;
  padding-right: 21px !important;
  margin-top: 0px;
  margin-bottom: 0px !important;
  padding-top: 4px;
}
.ant-menu-item {
  height: 48px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.ant-menu-item:hover,
.ant-menu-submenu:hover {
  background-color: #26a69a !important;
}
.templateMenu li.ant-menu-submenu ul li {
  padding-left: 45px !important;
  height: 48px !important;
}
.ant-menu-submenu-title {
  height: 48px !important;
  padding-top: 4px !important;
}
.capitalize {
  text-transform: capitalize;
}
.ant-layout-footer {
  padding: 25px 10px 10px 10px;
}
.ant-menu-item-selected,
.ant-menu-submenu-selected {
  background-color: #26a69a !important;
}
.ant-drawer-header {
  border-bottom: none !important;
}
.ant-layout-sider {
  overflow-y: auto;
  box-shadow: 0 1px 9px -3px rgba(0, 0, 0, 0.25);
}
.ant-layout-sider::-webkit-scrollbar {
  width: 0 !important;
}
.ant-layout-header {
  background: white;
  height: 48px;
  line-height: 48px;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background-color: #000002;
  background-color: transparent !important;
}
.colLoginLeft {
  height: 100vh;
  background-size: cover;
  padding: 30px;
}
.colLoginRight {
  padding: 50px;
  height: 100vh;
}
.divFormLogin {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 12vh;
}
.ant-menu-inline-collapsed li {
  text-align: center;
}
.ant-form-item-children input,
.ant-form-item-children textarea,
.ant-select-selection {
  background-color: #f3f6f9;
  color: #464e5f !important;
  height: 50px;
  border: 1px solid #f3f6f9;
}
.ant-input-number {
  height: 100%;
  border: 1px solid #d9d9d9;
}
.ant-select-selection__rendered {
  padding-top: 8px;
}
.btnLogin {
  height: 50px;
}
.descriptionLogin {
  color: white;
}
.titleLogin {
  color: white;
  font-size: 26px;
  margin-top: 40vh;
  margin-bottom: 0px;
  font-family: PoppinsSemibold, sans-serif;
}
.copyrightLogin {
  position: fixed;
  bottom: 5px;
}
.copyrightLogin,
.copyrightLogin a {
  color: white;
}
.layoutContainer {
  height: 100vh;
}
.titleSider {
  color: white;
  font-size: 20px;
  font-weight: lighter;
  text-transform: uppercase;
  width: 270px;
}
.navbarBrand {
  position: relative;
  padding-left: 30px;
  width: 316px;
  display: inline-block;
}
.navbarNav {
  display: flex;
  width: 100%;
}
.divIconTrigger {
  width: 50px;
  line-height: 52px;
  padding-left: 16px;
  cursor: pointer;
  font-size: 20px;
}
.cardTrimester {
  .ant-card-head {
    padding-left: 0px;
    padding-right: 0px;
    font-size: 14px;
  }
}
.navUser {
  flex-direction: row;
  display: flex;
  list-style: none;
  right: 0;
  position: absolute;
  padding-right: 30px;
}
.iconTriggerSider {
  color: white;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: unset;
}
.divIconTrigger:hover {
  background-color: #26a69a !important;
}
.cutomIcon {
  font-size: 18px !important;
  margin-right: 6px !important;
}
.colHeaderRight {
  text-align: right;
}
.iconProfileHeader {
  margin-left: 10px;
}
.profileNameHeader {
  color: white;
}
.footer {
  background: white;
  box-shadow: 0 10px 30px 0 rgba(82, 63, 105, 0.08);
}
.copyrightFooter {
  font-size: 12px;
}
.containerContent {
  padding: 30px 50px 30px 50px;
  display: block;
  min-height: 83vh;
  width: 100%;
}
.headerBottom {
  box-shadow: 0 10px 30px 0 rgba(82, 63, 105, 0.08);
  padding-left: 20px;
  padding-right: 20px;
}
.tagHeaderNow {
  padding: 8px;
  margin-right: 0px;
}
.tagHeaderToday {
  color: #b5b5c3;
}
.tagHeaderTodayDate {
  color: #26a69a;
}
.breadCrumbHeader {
  margin-top: 12px;
}
.btnSubmitCard {
  margin-bottom: 20px;
}
.containerButtonAction {
  display: block;
  text-align: center;
}
.btnActionDetail {
  margin: 5px;
}

.ant-btn-success {
  margin-right: 5px;
  margin-left: 5px;
}
.layoutContainerComponent {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.layoutContainerComponent::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

/* START MODAL */
.buttonCloseModal {
  position: absolute;
  right: 5px;
  font-family: PoppinsSemibold, sans-serif;
  background: no-repeat;
  border: none;
  color: #212121;
  font-size: 20px;
  top: 8px;
}
.buttonCloseModal:hover,
.buttonCloseModal:active,
.buttonCloseModal:focus {
  position: absolute;
  right: 5px;
  font-family: PoppinsSemibold, sans-serif;
  background: no-repeat;
  border: none;
  color: grey;
  font-size: 20px;
  top: 8px;
}
@keyframes modalFade {
  from {
    transform: translateY(-50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.modalStyleContainer section {
  max-height: 85vh;
  overflow-y: hidden;
  background: transparent;
}
.headerModal {
  color: white !important;
  padding-left: 25px;
  height: 50px;
  line-height: 48px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  text-align: center;
  border-bottom: 1px solid #ecf0f3;
}
.headerModal .headerTitle {
  font-weight: bold;
  color: #212121 !important;
}
.modalStyleContainer {
  animation-name: modalFade;
  animation-duration: 0.3s;
  top: 35px;
}
.contentModal {
  padding: 20px;
  padding-bottom: 0;
  background: #ffffff;
  max-height: 70vh;
  overflow-x: auto;
}
.contentModalBig {
  padding: 20px;
  padding-bottom: 0;
  background: #ffffff;
  max-height: 95vh;
  overflow-x: auto;
}
.alertErrorMessage {
  margin-bottom: 30px;
}

.footerModal {
  padding-left: 10px;
  padding-right: 10px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding-top: 10px !important;
  text-align: center;
  background: #ffffff;
}

.divBtnReloadVariantHistory {
  margin-bottom: 30px;
}
.btnSubmitModalRoundedGrey {
  width: 180px;
  background: #3e3e3e;
  border: 1px #3e3e3e solid;
  color: white;
}

.btnSubmitModalRoundedRed {
  width: 180px;
  background: #ffe2e5;
  border: none;
  color: #f64e60;
}

.btnSubmitModalRoundedBlue {
  width: 180px;
  background: #527ed6;
  border: 1px #527ed6 solid;
}
/* END MODAL */

.imgPreviewUppy {
  height: 100px;
  margin: 20px 0px 20px 0px;
  border: #dee2e6 solid 1px;
}
.drawerTitle {
  font-size: 1.3rem;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 550;
}
.btnCloseDrawer {
  position: absolute;
  right: 20px;
  top: 15px;
  height: 30px;
  width: 30px;
  padding-left: 8px;
  background-color: #26a69a;
  border-width: 0 1px 4px 1px;
  border-color: #1a746b !important;
}
.btnCloseDrawer:hover {
  background-color: #1a746b;
}
.iconCloseDrawer {
  font-size: 14px;
  margin-left: 0px;
  color: white;
}
.divHeaderProfile {
  cursor: pointer;
  display: inline-block;
  height: 85%;
  padding-left: 5px;
  padding-right: 5px;
}
.userProfileMenu:hover {
  background: #26a69a;
}
.avatarProfileDrawer {
  height: 95px;
  width: 95px;
  padding-top: 5px;
}
.avatarProfileDrawer i {
  font-size: 80px;
}
.profileName {
  font-family: PoppinsSemibold, sans-serif;
  font-size: 16px;
  margin-bottom: 0px;
}
.profileRole,
.profileEmail {
  margin-bottom: 0px;
  color: #b5b5c3;
}
.btnLogout {
  margin-top: 15px;
}
.btnAddItemSelect {
  margin-top: 15px;
  background: #e1f0ff !important;
  color: #3699ff;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}
.commonCardStyle {
  margin-bottom: 30px;
}
.pHistoryCapitalPrice {
  font-weight: bolder;
}
.btnSuggestPrice {
  background: #26a69a !important;
  color: #fff;
  height: 100px;
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
}
.suggestedPriceHistory {
  font-weight: bolder;
  font-size: 18px;
  margin-bottom: 10px;
}
.suggestedLastUsed,
.suggestedLastUsedBy {
  margin-bottom: 0;
}
.divContainerOption {
  position: relative;
  cursor: pointer;
}
.divContainerIcon {
  background-color: #f3f6f9;
  width: 40px;
  height: 40px;
  font-size: 30px;
  .anticon {
    padding-left: 6px;
  }
}
.divContainerOption:hover {
  padding: 5px;
  background: #26a69a;
}
.divContainerIcon span[role='img'] {
  font-size: 24px;
  margin-top: 8px;
  margin-left: 8px;
  color: #1ac5bd;
}
.pDrawerOptionTitle {
  position: absolute;
  top: 4px;
  left: 50px;
  font-family: PoppinsBold, sans-serif;
}
.pDrawerOptionDesc {
  position: absolute;
  top: 26px;
  left: 50px;
  font-size: 12px;
}
.colBtnSearchPeriod {
  position: relative;
}
.btnSearchPeriod {
  position: absolute;
  bottom: 0px;
}
.inputRefund {
  background-color: #c9f7f5;
}
.tableProfitLoss {
  display: table;
  width: 100%;
}
.profitLossName {
  width: 60%;
}
.profitLossCredit {
  width: 20%;
}
.profitLossDebit {
  width: 20%;
}
.tableProfitLossTitle {
  border-bottom-style: double !important;
  padding-top: 30px;
}
.profitLossEnd {
  border-bottom-style: double !important;
}
.profitLossFirst td {
  padding-top: 30px;
}
.netProfit {
  font-family: PoppinsBold, sans-serif;
}
.rowDashboard {
  margin-bottom: 30px;
}
.iconDashboard {
  font-size: 70px;
}
.titleCardDashboard {
  margin-bottom: 0px;
  margin-left: 30px;
  font-size: 16px;
}
.valueCardDashboard {
  font-weight: bolder;
  font-size: 40px;
  margin-bottom: 0px;
  margin-left: 30px;
}
.selectDescTitle,
.selectDescDecription {
  margin-bottom: 0;
}
.selectDescTitle {
  font-family: PoppinsBold, sans-serif;
}
.ant-select-selection-selected-value .selectDescTitle {
  font-family: Poppins, sans-serif;
  font-size: 14px;
}
.ant-select-selection-selected-value .selectDescDecription {
  display: none;
}
.imageNotFound {
  min-width: 300px;
  margin: auto;
  display: block;
  margin-top: 8vh;
}
.divNotFound {
  background: #20a8d8;
  width: 300px;
  margin: auto;
  text-align: center;
  font-size: 30px;
  color: white;
  border-radius: 10px;
  margin-top: 50px;
}
.pNotFound {
  margin: auto;
  display: block;
  text-align: center;
  font-size: 30px;
  margin-top: 30px;
}
.containerNotFound {
  height: 100vh;
  width: 100vw;
  display: flow-root;
}
.backToHome {
  display: block;
  margin: auto;
  margin-top: 30px;
  background: #20a8d8;
  border: #20a8d8;
}
.appNameLogin {
  text-transform: capitalize;
}
.rowTablePermission {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background: #fafbfb;
}
.rowTablePermissionHeader {
  background: #ecf0ef;
  font-weight: bolder;
}
.rowCheckboxPermission {
  margin: auto;
  text-align: center;
}
.rowPermissionList {
  height: 300px;
  overflow-y: auto;
}
.switchLanguage {
  margin-right: 30px;
  background-color: #26a69a;
}
.rrt-button {
  border: 0px !important;
  width: 50%;
}
.rrt-confirm-holder .rrt-confirm {
  border-radius: 0px !important;
}
.forgotPasswordLogin {
  text-align: right;
  cursor: pointer;
  text-decoration: underline;
}
.imageEsignHead {
  width: 100%;
  height: 200px;
  margin-bottom: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.signatureCanvas {
  border: 1px solid #707070;
  box-shadow: 0px 3px 6px #00000029;
}
.containerTotalDownload {
  margin-top: 30px;
  border: 3px solid #718792;
  padding: 15px;
  position: relative;
}
.__buttonCloseReactModal {
  position: absolute;
  font-size: 20px;
}
.modalLessonPlan {
  .ant-layout-content {
    min-height: 90vh;
  }
}
.ReactModal__Overlay--after-open {
  z-index: 999 !important;
}
.tableLessonPlan .__ReactTableWrap {
  max-height: 60vh;
}
