.ant-btn,
.rrt-ok-btn,
.rrt-cancel-btn {
  display: inline-block;
  font-weight: 700;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.rrt-ok-btn,
.rrt-cancel-btn {
  border-radius: 0px;
}
.ant-btn[disabled] {
  opacity: 0.65 !important;
  color: #fff;
}

.ant-btn-primary {
  background-color: #158cba !important;
  border-color: #127ba3;
  border-width: 0 1px 4px 1px;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: #117298 !important;
  border-color: #106a8c !important;
  color: #fff;
}
.ant-btn-primary[disabled] {
  background-color: #158cba !important;
  border-color: #158cba !important;
}
.ant-btn-info {
  background-color: #75caeb !important;
  border-color: #5fc1e8;
  border-width: 0 1px 4px 1px;
}
.ant-btn-info:hover,
.ant-btn-info:focus {
  background-color: #54bde6 !important;
  border-color: #48b9e5 !important;
  color: #fff;
}
.ant-btn-info[disabled] {
  background-color: #75caeb !important;
  border-color: #75caeb !important;
}
.ant-btn-danger,
.rrt-cancel-btn {
  background-color: #ff4136 !important;
  border-color: #ff291d;
  border-width: 0 1px 4px 1px;
}
.ant-btn-danger:hover,
.ant-btn-danger:focus {
  background-color: #ff1d10 !important;
  border-color: #ff1103 !important;
  color: #fff;
}
.ant-btn-danger[disabled] {
  background-color: #ff4136 !important;
  border-color: #ff4136 !important ;
}
.ant-btn-success,
.rrt-ok-btn {
  background-color: #28b62c !important;
  border-color: #23a127;
  border-width: 0 1px 4px 1px;
}
.ant-btn-success:hover,
.ant-btn-success:focus {
  background-color: #219724 !important;
  border-color: #1f8c22 !important;
  color: #fff;
}
.ant-btn-warning {
  background-color: #ff851b !important;
  border-color: #ff7702;
  border-width: 0 1px 4px 1px;
}
.ant-btn-warning:hover,
.ant-btn-warning:focus {
  background-color: #ff851b !important;
  border-color: #ff851b !important;
  color: #fff;
}
.ant-btn > .ant-btn-loading-icon .anticon {
  margin-right: 8px;
  padding-right: 0px;
}

.ant-btn:not(.disabled):hover {
  border-bottom-width: 0px !important;
}
.ant-input {
  box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.075);
  border-radius: 0.25rem;
  color: #555;
}
.ant-input-password {
  box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.075);
  border-radius: 0.25rem;
  .ant-input {
    box-shadow: none;
    border-radius: none;
    color: #555;
  }
}
.Toastify__toast {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.Toastify__toast--error {
  background-color: #ff4136 !important;
}
.Toastify__toast--success {
  background-color: #28b62c !important;
}
.ant-switch {
  background-color: #26a69a;
}
.ant-switch[aria-checked='false'] {
  background-color: #bfbfbf;
}
.errorListContainer {
  background-color: #ff4136 !important;
  border-width: 0 6px 6px 0px;
  border-color: #ff291d;
}
.errorListData {
  color: #fff;
}
