/* START TABLE */
.tableReact {
  width: 100%;
  background: #ffffff;
  color: #212529;
  margin-bottom: 1rem;
  border-collapse: collapse;
  border-spacing: 2px;
}
.theadTableReact tr {
  padding: 0.75rem;
}
.theadTableReact tr th {
  vertical-align: top;
  padding: 0.75rem;
  display: table-cell;
}
.tbodyTableReact {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}
.tbodyTableReact tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
.tbodyTableReact tr th,
.tbodyTableReact tr td {
  padding: 0.75rem;
  vertical-align: top;
}
.rowTableGreyStick {
  background: rgba(222, 226, 230, 1);
}
.rowTableWhiteStick {
  background: white;
}
.paginationTableReact {
  z-index: 1;
  margin-bottom: 30px;
  position: relative;
  width: 100%;
  input {
    margin-bottom: 3px;
  }
}
.navPagination {
  text-align: right;
  button {
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 2px;
  }
}
.globalFilterTable {
  float: right;
  margin-bottom: 10px;
}
.inputSearchGlobalFilter {
  font-size: '1.1rem';
  border: 0ch;
  background: rgba(222, 226, 230, 1);
}
.trTableHead {
  border-bottom: 2px solid white;
}
th[role='columnheader'] {
  background: rgba(222, 226, 230, 1);
}
.headerDatatableClassCenter {
  text-align: center;
}
.tableWrap {
  display: block;
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-bottom: 30px;
}
.containerTableGrid {
  display: grid;
}

/* END TABLE */
// Extra small devices (portrait phones, less than 576px)
@media (max-width: 576px) {
  .navPagination {
    position: relative;
    float: right;
    margin-top: 20px;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 577px) and (max-width: 767px) {
  .navPagination {
    position: relative;
    float: right;
    margin-top: 20px;
  }
}
